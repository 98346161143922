import styled from "styled-components"

const SectionTitle = styled.h2`
  text-transform: uppercase;
  color: ${props => props.theme.colors.primary.green};
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: 0.05em;
  margin-top: 24px;
  margin-bottom: 6px;
`

export default SectionTitle
