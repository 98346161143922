import React from "react"

//React components imports
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import GDPRContent from "../components/GDPRContent"

const GDPRPage = () => {
  return (
    <Layout>
      <PageTitle>Spracovanie osobných údajov</PageTitle>
      <GDPRContent />
    </Layout>
  )
}

export default GDPRPage
