import styled from "styled-components"

const Link = styled.a`
  text-decoration: none;
  color: ${props => props.theme.colors.primary.green};

  &:hover {
    text-decoration: underline;
  }
`

export default Link
