import React from "react"

//Styled components imports
import Wrapper from "./Wrapper"
import Text from "./Text"
import SectionTitle from "./SectionTitle"
import List from "./List"
import ListItem from "./ListItem"
import Link from "./Link"

const GDPRContent = () => {
  return (
    <Wrapper>
      <Text>
        V súvislosti so spracúvaním Vašich osobných údajov ako prevádzkovateľa
        Vám ako dotknutej osobe,ktorej osobné údaje spracúvame týmto poskytujeme
        informácie v zmysle  čl. 13, ods. 1, ods. 2, čl. 14 Nariadenia
        Európskeho parlamentu a Rady EÚ 2016/679 z 27. apríla 2016 o ochrane
        fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe takýchto
        údajov, ktorým sa zrušuje smernica 95/46/ES (všeobecné nariadenie o
        ochrane údajov, ďalej len ,,Nariadenie EP“) a § 19, ods. 1 , ods. 2 a §
        20 Z.č. 18/2018 Z.z. o ochrane osobných údajov (ďalej len ,, zákon o
        ochrane osobných údajov“).
      </Text>
      <SectionTitle>PREVÁDZKOVATEĽ</SectionTitle>
      <List>
        <ListItem>
          COMEX – S.N.C. s.r.o., Škultétyho námestie č.2, 990 01 Veľký Krtíš,
          zapísaná v OR OS V Banskej
        </ListItem>
        <ListItem>Bystrici oddiel: Sro, vložka č. 30892/S.</ListItem>
        <ListItem>
          Kontakt:  Ing. Denisa Šuhajová mobil : 0903 530 843, mail:
          hoteldolinavk@gmail.com
        </ListItem>
        <ListItem>Zodpovedná osoba : Ing. Denisa Šuhajová</ListItem>
      </List>
      <SectionTitle>OSOBNÉ ÚDAJE, KTORÉ SPRACOVÁVAME</SectionTitle>
      <List>
        <ListItem>meno a priezvisko prípadne názov spoločnosti</ListItem>
        <ListItem>
          adresu trvalého pobytu v domovskej krajine prípadne sídlo spoločnosti
        </ListItem>
        <ListItem>národnosť</ListItem>
        <ListItem>číslo OP prípadne pasu</ListItem>
        <ListItem>druh, číslo a platnosť víza</ListItem>
        <ListItem>mená a priezviská spolucestujúcich detí</ListItem>
        <ListItem>IČO, DIČ prípadne IČ DPH</ListItem>
        <ListItem>telefónne číslo a mail pokiaľ nám ho zadáte</ListItem>
      </List>
      <SectionTitle>
        POUŽITIE / ÚČEL SPRACOVANIA VAŠICH OSOBNÝCH ÚDAJOV
      </SectionTitle>
      <List>
        <ListItem>uzatváranie zmlúv</ListItem>
        <ListItem>zákonná povinnosť – vedenie hlásení pobytu</ListItem>
        <ListItem>účtovné a daňové účely</ListItem>
        <ListItem>archivácia a skartácia</ListItem>
      </List>
      <SectionTitle>PRÁVNY ZÁKLAD SPRACÚVANIA OSOBNÝCH ÚDAJOV</SectionTitle>
      <Text>
        Právnym základom je najmä čl. 6, ods. 1, písm. b) a c) GDPR, Občiansky
        zákonník, Obchodnýzákonník, účtovné, daňové zákony, zákon o pobyte
        cudzincov, zákon o archívoch a registratúrach. V niektorých prípadoch
        môže byť na spracúvanie Vašich osobných údajov podmienené Vašim
        súhlasom. K súhlasu Vás však nikto nebude nútiť a v prípade ak
        tento  súhlas poskytnete máte právo ho kedykoľvek odvolať  bez toho, aby
        to malo vplyv na zákonnosť  spracúvania založeného na súhlaseudelenom
        pred jeho odvolaním. 
      </Text>
      <SectionTitle>
        PRÍJEMCOVIA A SPROSTREDKOVATELIA VAŠICH OSOBNÝCH ÚDAJOV
      </SectionTitle>
      <Text>
        Orgány verejnej správy, cudzinecká a finančná polícia, orgán činný
        v trestnom konaní , COMEX – S.N.C. s.r.o., Škultétyho námestie č.2, PSČ
        : 990 01, IČO: 36 023 116. Vyššie uvedeným sprostredkovateľom sú vaše
        osobné údaje poskytované vždy na základe sprostredkovateľskej zmluvy a v
        súlade s minimálnym požadovaným štandardom ochrany osobných údajov v
        súlade s GDPR. Vaše osobné údaje nebudú poskytované do tretej krajiny
        ani do žiadnej medzinárodnej organizácii.
      </Text>
      <SectionTitle>
        DOBA SPRACÚVANIA A UCHOVÁVANIA OSOBNÝCH ÚDAJOV
      </SectionTitle>
      <Text>
        Vaše osobné údaje budeme uchovávať  po dobu určenú platnými právnymi
        predpismi týkajúcimi sa na vedenie a archiváciu knihy ubytovaných hostí,
        účtovné doklady 10 rokov. Automatizované rozhodovanie vrátane
        profilovania nevykonávame.
      </Text>
      <SectionTitle>PRÁVA DOTKNUTEJ OSOBY</SectionTitle>
      <Text>
        Právo na prístup k Vašim osobným údajom, právo na opravu Vašich osobných
        údajov ,právo na vymazanie Vašich osobných údajov, právo na obmedzenie
        spracúvania Vašich osobných údajov, právo namietať  proti spracúvaniu
        Vašich osobných údajov vrátane profilovania, právo na prenosnosť svojich
        osobných údajov, právo odvolať súhlas (ak je právnym základom
        spracúvania), Právo podať návrh na začatie konania Úradu na ochranu
        osobných údajov, právo na informáciu o zdroji osobných údajov, ak sa
        nezískali od Vás právo o existencii automatizovaného individuálneho
        rozhodovania vrátane profilovania, najmä o použitom postupe ako aj o
        význame predpokladaných dôsledkoch takéhoto spracúvania osobných údajov.
      </Text>
      <SectionTitle>OPATRENIA A POVINNOSŤ MLČANLIVOSTI</SectionTitle>
      <Text>
        Zároveň Vás informujeme, že sme prijali primerané technické a
        organizačné ochranné opatrenia na ochranu vašich osobných údajov. V
        prípade porušenia ochrany vašich osobných údajov sa zaväzujeme
        oznámiť vám, že k takémuto porušeniu ochrany vašich osobných údajov
        došlo a to bez zbytočného odkladu. Naša spoločnosť dodržiava
        mlčanlivosť o vašich osobných údajoch. Poskytovanie našich služieb nie
        je určené pre osoby mladšie ako 16 rokov, osobné údaje osôb mladších ako
        16 rokov nespracúvame. Pre uplatnení Vašich práv nás prosím kontaktujte
        na e‐mailové adrese:{" "}
        <Link href="mailto:hoteldolinavk@gmail.com">
          hoteldolinavk@gmail.com
        </Link>
        .
      </Text>
    </Wrapper>
  )
}

export default GDPRContent
